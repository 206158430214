<template>
  <div class="programme">
    <!-- PC -->
    <div
      class="
        productTop
        hidden-xs-only
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/swiper/swiperItem1.png" alt="" />
    </div>
    <!-- mobile -->
    <div
      class="
        mobileProductTop
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/mobile/index/index_1.png" alt="" />
    </div>
    <!-- pc -->
    <div class="location hidden-xs-only">
        <div class="section wow animate__animated animate__fadeInLeft">
            <div class="tarbar">
                <span>您的位置：</span>
                <span @click="handleChange(1)">首页</span>
                <span>></span>
                <span @click="goProduct()">产品及方案</span>
                <span>></span>
                <span class="newsCenter">{{detail.name}}</span>
            </div>
        </div>
    </div>
    <div class="programmeContent wow animate__animated animate__fadeInDown">
        <div class="section">
            <div class="left" v-if="listNew.length!==0">
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item :title="item.name" v-for="(item,index) in listNew" :name="item.value.toString()" :key="index">
                        <div :class="{ selected: active == items.id }" @click="getClick(items.id)" v-for="(items,index) in item.children" :key="index">{{items.name}}</div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <!-- <div class="right">
                <div class="items wow animate__animated animate__fadeIn" v-if="active==Ids[0]">
                    <div class="title"><span>{{detail.name}}</span></div>
                    <img class="image_1 hidden-xs-only" src="../../assets/images/product/line.png" alt="">
                    <img class="image_1 hidden-sm-and-up" src="../../assets/images/product/line_mobile.png" alt="">
                    <div class="box">
                        <div v-html="detail.profile"></div>
                        <div class="titleBold">企业专用互联网（DIA Service）和 企业多网通信（BGP Service）优势</div>
                        <ul>
                            <li>{{advantageOne[0]}}</li>
                            <li>{{advantageOne[1]}}</li>
                            <li>{{advantageOne[2]}}</li>
                            <li>{{advantageOne[3]}}</li>
                        </ul>
                        <div>企业边缘网络接入（ENAS）是基于我们的全球骨干网，面向中高端企业用户，使用多家运营商动态BGP，保证业务的低延时，高稳定性，旨在优化客户访问目标SaaS应用的效果，提升体验。</div>
                        <div class="titleBold">企业边缘网络接入（ENAS）优势</div>
                        <ul>
                            <li>{{advantageOne[4]}}</li>
                            <li>{{advantageOne[5]}}</li>
                            <li>{{advantageOne[6]}}</li>
                            <li>{{advantageOne[7]}}</li>
                            <li>{{advantageOne[8]}}</li>
                        </ul>
                    </div>
                </div>
                <div class="items wow animate__animated animate__fadeIn" v-if="active==Ids[1]">
                    <div class="title"><span>{{detail.name}}</span></div>
                    <img class="image_1 hidden-xs-only" src="../../assets/images/product/line.png" alt="">
                    <img class="image_1 hidden-sm-and-up" src="../../assets/images/product/line_mobile.png" alt="">
                    <div class="box">
                        <div v-html="detail.profile"></div>
                        <div class="titleBold">虚拟专用网优势</div>
                        <ul>
                            <li>{{advantageOne[0]}}</li>
                            <li>{{advantageOne[1]}}</li>
                            <li>{{advantageOne[2]}}</li>
                            <li>{{advantageOne[3]}}</li>
                        </ul>
                        <div v-html="detail.content"></div>
                        <div class="titleBold">多厂商托管软件定义广域网服务优势</div>
                        <ul>
                            <li>{{advantageOne[4]}}</li>
                            <li>{{advantageOne[5]}}</li>
                            <li>{{advantageOne[6]}}</li>
                            <li>{{advantageOne[7]}}</li>
                        </ul>
                    </div>
                </div>
                <div class="items wow animate__animated animate__fadeIn" v-if="active==Ids[2]">
                    <div class="title"><span>{{detail.name}}</span></div>
                    <img class="image_1 hidden-xs-only" src="../../assets/images/product/line.png" alt="">
                    <img class="image_1 hidden-sm-and-up" src="../../assets/images/product/line_mobile.png" alt="">
                    <div class="box">
                        <div v-html="detail.content"></div>
                        <div class="titleBold">金融短时延专线优势</div>
                        <ul>
                            <li>{{advantageOne[0]}}</li>
                            <li>{{advantageOne[1]}}</li>
                            <li>{{advantageOne[2]}}</li>
                            <li>{{advantageOne[3]}}</li>
                        </ul>
                        <div class="titleBold">快速云直连专线优势</div>
                        <ul>
                            <li>{{advantageOne[4]}}</li>
                            <li>{{advantageOne[5]}}</li>
                            <li>{{advantageOne[6]}}</li>
                            <li>{{advantageOne[7]}}</li>
                        </ul>
                    </div>
                </div>
                <div class="items wow animate__animated animate__fadeIn" v-if="active==Ids[3]">
                    <div class="title"><span>{{detail.name}}</span></div>
                    <img class="image_1 hidden-xs-only" src="../../assets/images/product/line.png" alt="">
                    <img class="image_1 hidden-sm-and-up" src="../../assets/images/product/line_mobile.png" alt="">
                    <div class="box">
                        <div v-html="detail.profile"></div>
                        <div v-html="detail.content"></div>
                        <div class="titleBold">上海市淮海中路99号大上海时代广场</div>
                        <ul>
                            <li>{{advantageOne[0]}}</li>
                            <li>{{advantageOne[1]}}</li>
                            <li>{{advantageOne[2]}}</li>
                        </ul>
                        <div class="titleBold">苏州工业园区苏虹西路99号 星海1号</div>
                        <ul>
                            <li>{{advantageOne[3]}}</li>
                            <li>{{advantageOne[4]}}</li>
                            <li>{{advantageOne[5]}}</li>
                        </ul>
                        <div class="titleBold">1 MASON, Irvine, CA 92618 尔湾3号</div>
                        <ul>
                            <li>{{advantageOne[6]}}</li>
                            <li>{{advantageOne[7]}}</li>
                            <li>{{advantageOne[8]}}</li>
                            <li>{{advantageOne[9]}}</li>
                        </ul>
                        <div class="titleBold">预制化模块化数据中心</div>
                        <ul>
                            <li>{{advantageOne[10]}}</li>
                            <li>{{advantageOne[11]}}</li>
                            <li>{{advantageOne[12]}}</li>
                            <li>{{advantageOne[13]}}</li>
                        </ul>
                    </div>
                </div>
                <div class="items wow animate__animated animate__fadeIn" v-if="active==Ids[4]">
                    <div class="title"><span>{{detail.name}}</span></div>
                    <img class="image_1 hidden-xs-only" src="../../assets/images/product/line.png" alt="">
                    <img class="image_1 hidden-sm-and-up" src="../../assets/images/product/line_mobile.png" alt="">
                    <div class="box">
                        <div v-html="detail.profile"></div>
                        <div v-html="detail.content"></div>
                        <ul>
                            <li>{{advantageOne[0]}}</li>
                            <li>{{advantageOne[1]}}</li>
                            <li>{{advantageOne[2]}}</li>
                            <li>{{advantageOne[3]}}</li>
                        </ul>
                    </div>
                </div>
                <div class="items wow animate__animated animate__fadeIn" v-if="active==Ids[5]">
                    <div class="title"><span>{{detail.name}}</span></div>
                    <img class="image_1 hidden-xs-only" src="../../assets/images/product/line.png" alt="">
                    <img class="image_1 hidden-sm-and-up" src="../../assets/images/product/line_mobile.png" alt="">
                    <div class="box">
                        <div v-html="detail.content"></div>
                        <div class="titleBold">服务特点</div>
                        <ul>
                            <li>{{advantageOne[0]}}</li>
                            <li>{{advantageOne[1]}}</li>
                            <li>{{advantageOne[2]}}</li>
                            <li>{{advantageOne[3]}}</li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="right" v-if="JSON.stringify(detail)!=='{}'">
                <div class="items">
                    <div class="title"><span>{{detail.name}}</span></div>
                    <img class="image_1 hidden-xs-only" src="../../assets/images/product/line.png" alt="">
                    <img class="image_1 hidden-sm-and-up" src="../../assets/images/product/line_mobile.png" alt="">
                    <div v-html="detail.content"></div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>
<script>
import axios from "axios";
import { productPage,productDetail } from '../../api/management'
export default {
  data() {
    return {
        id:this.$route.query.id,
        type:1,
        url:process.env.VUE_APP_WEB_URL,
        active:'',
        activeName:this.$route.query.id,
        // lists:[
        //     {
        //         id:'21',
        //         title:'南捷云网集成方案',
        //         children:[
        //             {
        //                 id:11,
        //                 title:'互联网接入服务',
        //             },
        //             {
        //                 id:22,
        //                 title:'虚拟专用网服务',
        //             },
        //             {
        //                 id:33,
        //                 title:'低延时专网服务',
        //             }
        //         ]
        //     },
        //     {
        //         id:'22',
        //         title:'南捷基础设施服务',
        //         children:[
        //             {
        //                 id:44,
        //                 title:'数据中心服务',
        //             },
        //             {
        //                 id:55,
        //                 title:'云管理服务',
        //             },
        //             {
        //                 id:66,
        //                 title:'行业解决方案',
        //             }
        //         ]
        //     }
        // ],
        list:[],
        InfoOne:{
          current:1,
          pageSize:10,
          categoryId:'',
          display:true
        },
        InfoTwo:{
            current:1,
            pageSize:10,
            categoryId:'',
            display:true
        },
        InfoTwoList:[],
        InfoOneList:[],
        listNew:[],
        detail:{},
        Ids:[],
        advantageOne:[],
        advantageTwo:[],
        advantageThree:[],
        advantageFour:[],
    };
  },
  mounted(){
    
  },
  created() {
    // 产品的分类
    this.getCategoryTreeList()
  },
  methods:{
    // 产品分类
    getCategoryTreeList() {
      axios({
        method: "post",
        url: this.url+`/category/treeList/`+this.type,
      }).then(res => {
         this.list = res.data
         this.getProductPageOne()
         this.getProductPageTwo()
      })
    },
    // 产品列表
    async getProductPageOne() {
      this.InfoOne.categoryId = this.list[0].value
      const res = await productPage(this.InfoOne) 
      this.InfoOneList = res.data.records.reverse()
      setTimeout(() => {
        this.getHandle()
      }, 100);
    },
    async getProductPageTwo() {
      this.InfoTwo.categoryId = this.list[1].value
      const res = await productPage(this.InfoTwo) 
      this.InfoTwoList = res.data.records.reverse()
    },
    getHandle() {
        let listAll = this.InfoOneList.concat(this.InfoTwoList)
        listAll.forEach(item=>{
            this.Ids.push(item.id)
        })
        let listOne = this.list[0]
        let listTwo = this.list[1]
        listOne.children = []
        listTwo.children = []
        this.InfoOneList.forEach(item=>{
            listOne.children.push(item)
        })
        this.listNew.push(listOne)
        this.InfoTwoList.forEach(item=>{
            listTwo.children.push(item)
        })
        this.listNew.push(listTwo)
        
        this.getId()
    },
    handleChange(num) {
      this.$store.commit("changeNum", num);
      this.$router.push('/index')
    },
    getClick(id) {
        this.active = id
        this.getProductDetail(id)
    },
    async getProductDetail(id) {
        const res = await productDetail(id)
        this.detail = res.data
        // console.log(this.detail);
        this.advantageOne = res.data.advantage.split('-')
    },
    getId(){
        this.listNew.forEach(item=>{
            if(item.value==this.id) {
                this.active=item.children[0].id
                let id = item.children[0].id
                this.getProductDetail(id)
            }
        })
    },
    goProduct() {
        this.$router.push('/product')
    }
  },
};
</script>
<style lang="scss">
.programme {
    padding-top: 92px;
    .productTop {
        img {
        width: 100%;
        height: 482px;
        }
    }
    .location {
        padding: 50px 0;
        .section {
            font-size: 18px;
            .tarbar {
                cursor: pointer;
                .newsCenter {
                    color: #197dbe;
                }
            }
        }
    }
    .programmeContent {
        margin: 40px 0;
        .section {
            display: flex;
            .left {
                margin-top: 40px;
                width:306px;
                .el-collapse {
                    border: 1px solid #dcdcdc!important;
                    .el-collapse-item {
                        width: 304px;
                        .el-collapse-item__header{
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            border: none;
                            color: #fff;
                            font-size: 20px;
                            background: linear-gradient(to top right, #25aae1, #197dbe);
                            .el-collapse-item__arrow  {
                                display: none;
                            }
                        }
                    }
                    .el-collapse-item__wrap{
                            border: none;
                            .el-collapse-item__content {
                                padding-bottom: 0;
                                div {
                                    padding-left: 20px;
                                    font-size: 16px;
                                    line-height: 48px;
                                    // color: #197dbe;
                                    cursor: pointer;
                                    border-bottom: 1px solid #dcdcdc!important;
                                }
                                .selected {
                                    color: #197dbe;
                                }
                            }
                    }
                }
            }
            .right {
                width: 890px;
                padding-left: 20px;
                .items {
                    .title {
                        display: flex;
                        justify-content: center;
                        span {
                            font-size: 30px;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    .image_1 {
                        width: 100%;
                        margin: 20px 0 12px 0;
                    }
                    .box {
                        .titleBold {
                            padding: 10px 0;
                            font-weight: bold;
                        }
                        div {
                            font-size: 18px;
                            line-height: 28px;
                        }
                        ul {
                            padding-left:20px ;
                            font-size: 16px;
                            line-height: 28px;
                            li::marker{
                                color: #197cbb;
                            }
                        }
                        img {
                            width: 100%;
                        }
                        .images_2 {
                            text-align: center;
                            margin: 20px 40px;
                            .imageText {
                                font-size: 39px;
                                color: #fff;
                                background-color: #043c69;
                                padding: 20px 0;
                            }
                        }
                        .images_3 {
                            margin: 20px 0;
                            display: flex;
                            img {
                                width: 50%;
                            }
                        }
                        .images_4 {
                            margin: 20px 100px;
                            display: flex;
                            img {
                                width: 50%;
                            }
                            .images_4_one{
                                margin-right: 20px;
                            }
                        }
                        .images_44 {
                            margin: 20px 40px;
                            display: flex;
                            .images_44_one{
                                img {
                                    height: 230px;
                                    width: 100%;
                                }
                                .imageText {
                                    text-align: center;
                                    font-size: 18px;
                                    color: #fff;
                                    background-color: #043c69;
                                    padding: 10px 0;
                                }
                            }
                            .images_44_one:first-child {
                                margin-right: 20px;
                            }
                        }
                        .images_5 {
                            margin: 20px 0;
                            display: flex;
                            img {
                                width: 31%;
                            }
                            .images_4_one{
                                margin-right: 20px;
                            }
                        }
                        .images_6 {
                            margin: 20px 0;
                            display: flex;
                            img {
                                width: 50%;
                            }
                            .images_4_one{
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
  .programme {
    padding-top: 0;
    .mobileProductTop {
      img {
        height: 4rem;
      }
    }
    .programmeContent {
        margin: .5rem 0;
        .section {
            display: flex;
            flex-direction: column;
            .left {
                width: 100%;
                margin-top: 0;
                .el-collapse {
                    .el-collapse-item {
                        width: 100%;
                        .el-collapse-item__header{
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            border: none;
                            color: #fff;
                            font-size: .4rem;
                            background: linear-gradient(to top right, #25aae1, #197dbe);
                            .el-collapse-item__arrow  {
                                display: none;
                            }
                        }
                    }
                    .el-collapse-item__wrap{
                            border: none;
                            .el-collapse-item__content {
                                text-align: center;
                                padding: 0;
                                border-bottom: none;
                                div {
                                    border-bottom: 1px solid #dcdcdc!important;
                                    font-size: .35rem;
                                    line-height: 1rem;
                                    // color: #197dbe;
                                    cursor: pointer;
                                }
                                .selected {
                                    color: #197dbe;
                                }
                            }
                    }
                }
            }
            .right {
                width: 100%;
                padding-left: 20px;
                .items {
                    .title {
                        margin-top: .3rem;
                        display: flex;
                        justify-content: center;
                        span {
                            font-size: .45rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    .box {
                        div {
                            font-size: .32rem;
                            line-height: .5rem;
                        }
                        ul {
                            padding-left:20px ;
                            font-size: .3rem;
                            line-height: .5rem;
                            li::marker{
                                color: #197cbb;
                            }
                        }
                        .titleBold {
                            padding: .1rem 0;
                            font-weight: bold;
                        }
                        .image_1 {
                            width: 100%;
                            margin: .2rem 0;
                        }
                        .images_2 {
                            text-align: center;
                            margin: .2rem;
                            img {
                                width: 100%;
                            }
                            .imageText {
                                font-size: .33rem;
                                color: #fff;
                                background-color: #043c69;
                                padding: 0.1rem 0;
                            }
                        }
                        .images_3 {
                            margin: .2rem 0;
                            display: flex;
                            img {
                                width: 50%;
                            }
                        }
                        .images_4 {
                            margin: .2rem 0;
                            display: flex;
                            img {
                                width: 46%;
                            }
                            .images_4_one{
                                margin-right: 20px;
                            }
                        }
                        .images_44 {
                            margin: .4rem;
                            display: flex;
                            flex-direction: column;
                            .images_44_one{
                                img {
                                    height: 100%;
                                    width: 100%;
                                }
                                .imageText {
                                    text-align: center;
                                    font-size: .3rem;
                                    color: #fff;
                                    background-color: #043c69;
                                    padding: .1rem 0;
                                }
                            }
                            .image_one {
                                margin-top: .4rem;
                            }
                            .images_44_one:first-child {
                                margin-right: 0;
                            }
                        }
                        .images_5 {
                            margin: .2rem 0;
                            display: flex;
                            img {
                                width: 31%;
                            }
                            .images_4_one{
                                margin-right: .1rem;
                            }
                        }
                        .images_6 {
                            margin: .2rem 0;
                            display: flex;
                            img {
                                width: 50%;
                            }
                            .images_4_one{
                                margin-right: .1rem;
                            }
                        }
                    }
                }
            }
        }
    }
  }
}
</style>